import chains from 'components/Chain/chains';
import { Chain, defineChain } from 'viem';
import {
  arbitrum as arbitrumMainnet,
  arbitrumSepolia as arbitrumSepoliaTestnet,
  mainnet as ethereumMainnet,
  sepolia as ethereumSepolia,
  gnosisChiado as gnosisChiadoTestnet,
  gnosis as gnosisMainnet,
  linea as lineaMainnet,
  lineaSepolia as lineaSepoliaTestnet,
  polygonAmoy as polygonAmoyTestnet,
  polygon as polygonMainnet,
  scroll as scrollMainnet,
  scrollSepolia as scrollSepoliaTestnet,
} from 'viem/chains';
import { ViemChains } from './types';

const ethereumLocalChain = chains.find((chain) => chain.chainId === '6666');
const caminoChain = chains.find((chain) => chain.chainId === '500');
const columbusChain = chains.find((chain) => chain.chainId === '501');
const sepoliaChain = chains.find((chain) => chain.chainId === '11155111');

export const ethereumLocal = ethereumLocalChain
  ? defineChain({
      id: Number(ethereumLocalChain.chainId),
      name: ethereumLocalChain.label,
      nativeCurrency: {
        decimals: 18,
        name: 'Ethereum',
        symbol: 'tETH',
      },
      rpcUrls: {
        default: {
          http: [ethereumLocalChain?.explorerUrl],
        },
        public: {
          http: [ethereumLocalChain?.explorerUrl],
        },
      },
      blockExplorers: {
        default: {
          name: 'Etherscan',
          url: 'https://etherscan.io',
        },
      },
      testnet: true,
      iconUrl: '/assets/emoney/chains/ethereum-icon-sm.svg',
    })
  : undefined;

export const columbus = columbusChain
  ? defineChain({
      id: Number(columbusChain.chainId),
      name: columbusChain.label,
      nativeCurrency: {
        decimals: 18,
        name: 'CAM',
        symbol: 'CAM',
      },
      rpcUrls: {
        public: {
          http: [`https://columbus.camino.network/ext/bc/C/rpc`],
          webSocket: ['wss://columbus.camino.network/ext/bc/C/ws'],
        },
        default: {
          http: ['https://columbus.camino.network/ext/bc/C/rpc'],
          webSocket: ['wss://columbus.camino.network/ext/bc/C/ws'],
        },
      },
      blockExplorers: {
        etherscan: { name: 'CaminoScan', url: columbusChain?.explorerUrl },
        default: { name: 'CaminoScan', url: columbusChain?.explorerUrl },
      },
    })
  : undefined;

export const camino = caminoChain
  ? defineChain({
      id: Number(caminoChain.chainId),
      name: caminoChain.label,
      nativeCurrency: {
        decimals: 18,
        name: 'CAM',
        symbol: 'CAM',
      },
      rpcUrls: {
        public: {
          http: ['https://api.camino.network/ext/bc/C/rpc'],
          webSocket: ['wss://api.camino.network/ext/bc/C/ws'],
        },
        default: {
          http: ['https://api.camino.network/ext/bc/C/rpc'],
          webSocket: ['wss://api.camino.network/ext/bc/C/ws'],
        },
      },
      blockExplorers: {
        etherscan: {
          name: 'CaminoScan',
          url: caminoChain?.explorerUrl,
        },
        default: {
          name: 'CaminoScan',
          url: caminoChain?.explorerUrl,
        },
      },
    })
  : undefined;

export const sepolia = sepoliaChain
  ? defineChain({
      ...ethereumSepolia,
      name: chains.find((chain) => chain.id === 'sepolia')?.label as string,
    })
  : undefined;

export const amoy = {
  ...polygonAmoyTestnet,
  iconUrl: '/assets/emoney/chains/polygon-icon-sm.svg',
};

export const chiado = {
  ...gnosisChiadoTestnet,
  iconUrl: '/assets/emoney/chains/gnosis-icon-sm.svg',
};

export const arbitrumSepolia = arbitrumSepoliaTestnet;
export const lineaSepolia = lineaSepoliaTestnet;
export const scrollSepolia = scrollSepoliaTestnet;

export const gnosis = gnosisMainnet;

export const arbitrum = arbitrumMainnet;
export const ethereum = ethereumMainnet;
export const polygon = polygonMainnet;
export const linea = lineaMainnet;
export const scroll = scrollMainnet;

export type ChainEvm = Chain;

export const localhostEvmChains = [ethereumLocal];

export default [
  amoy,
  arbitrum,
  arbitrumSepolia,
  chiado,
  ethereum,
  gnosis,
  polygon,
  sepolia,
  linea,
  lineaSepolia,
  camino,
  columbus,
  scroll,
  scrollSepolia,
  ...localhostEvmChains,
] as ViemChains;
