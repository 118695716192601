import { useAuthContext } from 'components/Login/hooks';
import React, { createContext, useContext } from 'react';
import restQuery from './rest-query';

const LOGGING_INTERVAL = 5000;

export type LogSeverity = 'info' | 'warning' | 'error';

interface LogPayload {
  message: string;
  severity?: LogSeverity;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>;
}

interface analyticsPayload {
  event: string;
  meta?: Record<string, string>;
}

const LoggerContext = createContext<
  | {
      logMessage: (payload: LogPayload) => void;
      logAnalyticsEvent: (payload: analyticsPayload) => void;
    }
  | undefined
>(undefined);

const logCache: Record<string, number> = {};

// Allow to be called directly if needed, client side only.
export async function logMessageRequest(payload: LogPayload) {
  if (typeof window === 'undefined') {
    // No need to run this on the server, rest service doesn't handle it.
    return;
  }
  // Deduplication prevents identical log messages from being sent repeatedly
  // within a short interval, reducing noise and unnecessary server requests.
  const cacheKey = `${payload.severity || 'error'}:${payload.message}`;
  const now = Date.now();
  if (logCache[cacheKey] && now - logCache[cacheKey] < LOGGING_INTERVAL) {
    return;
  }

  logCache[cacheKey] = now;

  const finalPayload: LogPayload = {
    severity: payload.severity || 'error',
    message: payload.message,
    meta: payload.meta,
  };

  try {
    await restQuery.post(`/log`, finalPayload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('logging failed', error);
  } finally {
    if (finalPayload.severity === 'error') {
      console.error(finalPayload.message, finalPayload.meta);
    }
  }
}

export const LoggerProvider = ({ children }: { children: React.ReactNode }) => {
  // Use separate caches for server and client
  const logCacheRef = React.useRef<Record<string, number>>(
    typeof window === 'undefined'
      ? {} // Server cache (recreated per request)
      : {}, // Client cache (persists between renders)
  );

  // Client-side cleanup only
  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      const cache = logCacheRef.current;

      // Remove entries older than LOGGING_INTERVAL
      Object.keys(cache).forEach((key) => {
        if (now - cache[key] > LOGGING_INTERVAL) {
          delete cache[key];
        }
      });
    }, LOGGING_INTERVAL);

    return () => clearInterval(cleanupInterval);
  }, []);

  const logMessage = async (payload: LogPayload) => {
    const { data } = useAuthContext();
    const { userId } = data || {};

    // Lazy load history
    const { default: history } = await import('utils/history');
    const pathname = history?.location.pathname;

    // Add userId and path to the payload.
    const extendedPayload = {
      ...payload,
      meta: {
        ...payload.meta,
        userId: userId,
        ...(pathname && { pathname }),
        isServer: typeof window === 'undefined',
      },
    };
    logMessageRequest(extendedPayload);
  };

  const logAnalyticsEvent = (payload: analyticsPayload) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', payload.event, payload.meta);
    } else {
      console.warn('Google Analytics is not initialized');
    }
  };

  return (
    <LoggerContext.Provider value={{ logMessage, logAnalyticsEvent }}>
      {children}
    </LoggerContext.Provider>
  );
};

export const useLogger = () => {
  const context = useContext(LoggerContext);
  if (!context) {
    throw new Error('useLogger must be used within a LoggerProvider');
  }
  return context;
};
