import { AnyAction } from 'redux';
import { STORE_SUCCESS as USER_STORE_SUCCESS } from '../User/constants';
import {
  CREATE_VERSION_FAILURE,
  CREATE_VERSION_REQUEST,
  CREATE_VERSION_SUCCESS,
  READ_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  REMOVE_PERSON_FAILURE,
  REMOVE_PERSON_REQUEST,
  REMOVE_PERSON_SUCCESS,
  REMOVE_ROLE_FAILURE,
  REMOVE_ROLE_REQUEST,
  REMOVE_ROLE_SUCCESS,
  RESET_FAILURE,
  RESET_REQUEST,
  RESET_SUCCESS,
  STORE_DETAILS_FAILURE,
  STORE_DETAILS_REQUEST,
  STORE_DETAILS_SUCCESS,
  STORE_FAILURE,
  STORE_FORM_FAILURE,
  STORE_FORM_REQUEST,
  STORE_FORM_SUCCESS,
  STORE_REQUEST,
  STORE_ROLES_FAILURE,
  STORE_ROLES_REQUEST,
  STORE_ROLES_SUCCESS,
  STORE_SUCCESS,
  STORE_VERIFICATIONS_FAILURE,
  STORE_VERIFICATIONS_REQUEST,
  STORE_VERIFICATIONS_SUCCESS,
  VERSIONS_FAILURE,
  VERSIONS_REQUEST,
  VERSIONS_SUCCESS,
} from './constants';
import { ProfileReduxStore } from './types';

export const initial: ProfileReduxStore = {
  detail: undefined,
  versions: undefined,
  isReadRequest: false,
  isReadSuccess: false,
  isReadFailure: undefined,
  isStoreRequest: false,
  isStoreSuccess: false,
  isStoreFailure: undefined,
  isStoreDetailsRequest: false,
  isStoreDetailsSuccess: false,
  isStoreDetailsFailure: undefined,
  isStoreFormRequest: false,
  isStoreFormSuccess: false,
  isStoreFormFailure: undefined,
  isStoreRolesRequest: false,
  isStoreRolesSuccess: false,
  isStoreRolesFailure: undefined,
  isRemoveRoleRequest: false,
  isRemoveRoleSuccess: false,
  isRemoveRoleFailure: undefined,
  isStoreVerificationsRequest: false,
  isStoreVerificationsSuccess: false,
  isStoreVerificationsFailure: undefined,
  isCreateVersionRequest: false,
  isCreateVersionSuccess: false,
  isCreateVersionFailure: undefined,
  isVersionsRequest: false,
  isVersionsSuccess: false,
  isVersionsFailure: undefined,
  isRemovePersonRequest: false,
  isRemovePersonSuccess: false,
  isRemovePersonFailure: undefined,
  isResetRequest: false,
  isResetSuccess: false,
  isResetFailure: undefined,
  validationErrors: undefined,
};

export default (
  state: ProfileReduxStore = initial,
  action: AnyAction,
): ProfileReduxStore => {
  switch (action.type) {
    case READ_REQUEST: {
      return { ...state, isReadRequest: true };
    }
    case READ_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: item,
        isReadRequest: false,
        isReadSuccess: true,
        isReadFailure: undefined,
      };
    }
    case READ_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isReadRequest: false,
        isReadSuccess: false,
        isReadFailure: error,
      };
    }

    case STORE_REQUEST: {
      const { item, error } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreRequest: true,
        validationErrors: error,
      };
    }

    // we need to detect when user changes are made to allow submitting a new
    // profile version.
    case USER_STORE_SUCCESS: {
      return {
        ...state,
        detail: {
          ...(state.detail || {}),
          meta: {
            ...(state.detail?.meta || {}),
            state: 'draft',
          },
        },
      };
    }

    case STORE_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreRequest: false,
        isStoreSuccess: true,
        isStoreFailure: undefined,
      };
    }
    case STORE_FAILURE: {
      const { error } = action;

      return {
        ...state,
        isStoreRequest: false,
        isStoreSuccess: false,
        isStoreFailure: error,
        validationErrors: error,
      };
    }

    case STORE_DETAILS_REQUEST: {
      const { item, error } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreDetailsRequest: true,
        validationErrors: error,
      };
    }
    case STORE_DETAILS_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreDetailsRequest: false,
        isStoreDetailsSuccess: true,
        isStoreDetailsFailure: undefined,
      };
    }
    case STORE_DETAILS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isStoreDetailsRequest: false,
        isStoreDetailsSuccess: false,
        isStoreDetailsFailure: error,
        validationErrors: error,
      };
    }

    case STORE_FORM_REQUEST: {
      const { item, error } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreFormRequest: true,
        validationErrors: error,
      };
    }
    case STORE_FORM_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreFormRequest: false,
        isStoreFormSuccess: true,
        isStoreFormFailure: undefined,
      };
    }
    case STORE_FORM_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isStoreFormRequest: false,
        isStoreFormSuccess: false,
        isStoreFormFailure: error,
        validationErrors: error,
      };
    }

    case STORE_VERIFICATIONS_REQUEST: {
      const { error } = action;
      return {
        ...state,
        isStoreVerificationsRequest: true,
        validationErrors: error,
      };
    }
    case STORE_VERIFICATIONS_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreVerificationsRequest: false,
        isStoreVerificationsSuccess: true,
        isStoreVerificationsFailure: undefined,
      };
    }
    case STORE_VERIFICATIONS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isStoreVerificationsRequest: false,
        isStoreVerificationsSuccess: false,
        isStoreVerificationsFailure: error,
        validationErrors: error,
      };
    }

    case STORE_ROLES_REQUEST: {
      const { item, error } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isStoreRolesRequest: true,
        validationErrors: error,
      };
    }
    case STORE_ROLES_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isStoreRolesRequest: false,
        isStoreRolesSuccess: true,
        isStoreRolesFailure: undefined,
      };
    }
    case STORE_ROLES_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isStoreRolesRequest: false,
        isStoreRolesSuccess: false,
        isStoreRolesFailure: error,
        validationErrors: error,
      };
    }

    case REMOVE_ROLE_REQUEST: {
      const { error } = action;
      return {
        ...state,
        isRemoveRoleRequest: true,
        validationErrors: error,
      };
    }
    case REMOVE_ROLE_SUCCESS: {
      return {
        ...state,
        isStoreRolesRequest: false,
        isStoreRolesSuccess: true,
        isStoreRolesFailure: undefined,
      };
    }
    case REMOVE_ROLE_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isRemoveRoleRequest: false,
        isRemoveRoleSuccess: false,
        isRemoveRoleFailure: error,
        validationErrors: error,
      };
    }

    case RESET_REQUEST: {
      return { ...state, isResetRequest: true };
    }

    case RESET_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: item,
        isResetRequest: false,
        isResetSuccess: true,
        isResetFailure: undefined,
      };
    }
    case RESET_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isResetRequest: false,
        isResetSuccess: false,
        isResetFailure: error,
        validationErrors: error,
      };
    }

    case REMOVE_PERSON_REQUEST: {
      const { item, error } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          ...(item || {}),
        },
        isRemovePersonRequest: true,
        validationErrors: error,
      };
    }
    case REMOVE_PERSON_SUCCESS: {
      const { item } = action;
      return {
        ...state,
        detail: { ...(state.detail || {}), ...(item || {}) },
        isRemovePersonRequest: false,
        isRemovePersonSuccess: true,
        isRemovePersonFailure: undefined,
      };
    }
    case REMOVE_PERSON_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isRemovePersonRequest: false,
        isRemovePersonSuccess: false,
        isRemovePersonFailure: error,
        validationErrors: error,
      };
    }

    case CREATE_VERSION_REQUEST: {
      return {
        ...state,
        isCreateVersionRequest: true,
        isCreateVersionFailure: undefined,
        validationErrors: undefined,
      };
    }
    case CREATE_VERSION_SUCCESS: {
      const { item } = action;

      return {
        ...state,
        detail: item,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: undefined,
        validationErrors: undefined,
      };
    }
    case CREATE_VERSION_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isCreateVersionRequest: false,
        isCreateVersionSuccess: false,
        isCreateVersionFailure: error,
        validationErrors: error,
      };
    }

    case VERSIONS_REQUEST: {
      return { ...state, isVersionsRequest: true };
    }
    case VERSIONS_SUCCESS: {
      const { versions } = action;
      return {
        ...state,
        versions,
        isVersionsRequest: false,
        isVersionsSuccess: true,
        isVersionsFailure: undefined,
      };
    }
    case VERSIONS_FAILURE: {
      const { error } = action;
      return {
        ...state,
        isVersionsRequest: false,
        isVersionsSuccess: false,
        isVersionsFailure: error,
      };
    }

    default:
      return state;
  }
};
