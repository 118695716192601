import allChains from 'components/Chain/chains';
import { Environment } from 'types/app';
import { Chain } from 'types/emoney/Chain';
import { chainSortOrder } from 'utils/emoney/chains';

export const sortChains = (chains: Chain[]) => {
  const orderMap = new Map<string, number>();
  chainSortOrder.forEach((chain, index) => {
    orderMap.set(chain, index);
  });

  // Sort the chains based on the order in chainSortOrder
  return chains.sort((a, b) => {
    const indexA = orderMap.get(a.chain) ?? Number.MAX_SAFE_INTEGER;
    const indexB = orderMap.get(b.chain) ?? Number.MAX_SAFE_INTEGER;
    return indexA - indexB;
  });
};

export function getFallbackChain(environment: Environment): Chain | undefined {
  const ethereumLocal = allChains.find((chain) => chain?.chainId === '6666');
  const ethereumTestnet = allChains.find(
    (chain) => chain?.chainId === '11155111',
  );
  const ethereumMainnet = allChains.find((chain) => chain?.chainId === '1');

  if (environment === 'dev') {
    return ethereumLocal;
  }
  if (environment === 'staging' || environment === 'sandbox') {
    return ethereumTestnet;
  }
  return ethereumMainnet;
}
