import restQuery from 'services/rest-query';
import { AuthContext, LoginRequest, LogoutResponse } from 'types/iam/Login';

const authContext = (): Promise<AuthContext> =>
  restQuery.get('/api/iam/auth/context');

const login = (data: LoginRequest): Promise<AuthContext> =>
  restQuery.post('/api/iam/login', data);

const logout = (): Promise<LogoutResponse> => restQuery.get('/api/iam/logout');

const read = (id: string): Promise<AuthContext> =>
  restQuery.get(`/api/iam/users/${id}`);

export default {
  authContext,
  login,
  logout,
  read,
};
