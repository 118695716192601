import allChains from 'components/Chain/chains';
import { Chain } from 'types/emoney/Chain';
import { Token } from 'types/emoney/Token';

export function uniqueChainIds(tokens: Token[]): string[] {
  return Array.from(new Set(tokens?.map((c) => c.chainId)));
}

export function getChainsFromTokens(tokens: Token[]) {
  const chainIds = tokens.length ? uniqueChainIds(tokens) : [];
  return chainIds
    .map((chainId) => allChains.find((chain) => chain.chainId === chainId))
    .filter(Boolean) as Chain[];
}
