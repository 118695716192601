'use client';

import { useChains } from 'components/Chain/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import React, { useState } from 'react';
import { logMessageRequest } from 'services/logger';
import { WagmiProvider, cookieToInitialState } from 'wagmi';
import evmChains, { ethereum, ethereumLocal, sepolia } from './chains';
import { config as getWagmiConfig } from './config';
import { ViemChains } from './types';

const EvmProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const { environment, cookies } = useSharedAppContext();

    const chains = useChains('evm');

    const wagmiChains = () => {
      if (!chains.length) {
        logMessageRequest({
          message:
            'MAJOR ISSUE: No chains found when initializing WagmiProvider',
          severity: 'error',
        });
        // Fallback to initialize the WagmiProvider.
        if (environment === 'dev') {
          return [ethereumLocal] as ViemChains;
        }
        if (environment === 'staging' || environment === 'sandbox') {
          return [sepolia] as ViemChains;
        }
        return [ethereum] as ViemChains;
      }
      return evmChains.filter((evmChain) =>
        chains?.some((chain) => Number(chain?.chainId) === evmChain.id),
      ) as unknown as ViemChains;
    };

    const [wagmiConfig] = useState(getWagmiConfig(environment, wagmiChains()));

    const wagmiInitialState =
      cookieToInitialState(wagmiConfig, cookies) || undefined;
    return (
      <WagmiProvider
        config={wagmiConfig}
        initialState={wagmiInitialState}
        reconnectOnMount
      >
        {children}
      </WagmiProvider>
    );
  },
);

EvmProvider.displayName = 'EvmProvider';

export default EvmProvider;
