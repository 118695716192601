import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyPrefix } from 'providers/QueryProvider';
import service from './service';

export const useLogin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: keyPrefix(['iam-login']),
    mutationFn: service.login,
    onSuccess: (data) => {
      queryClient.setQueryData(keyPrefix(['iam-auth-context']), data);
      queryClient.invalidateQueries({
        queryKey: keyPrefix(['emoney-tokens-list']),
      });
    },
  });
};
export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: keyPrefix(['iam-logout']),
    mutationFn: service.logout,
    onSuccess: () => {
      queryClient.clear();
      queryClient.invalidateQueries({
        queryKey: keyPrefix(['iam-auth-context']),
      });
    },
  });
};

export function useAuthContext() {
  return useQuery({
    queryKey: keyPrefix(['iam-auth-context']),
    queryFn: service.authContext,
    staleTime: 10000,
    gcTime: 1000 * 60 * 60 * 3, // 3 hours in milliseconds
    retry: false,
  });
}

export function useIsVerified() {
  const authContextQuery = useAuthContext();

  return (
    authContextQuery.isSuccess === true &&
    !!authContextQuery.data &&
    authContextQuery.data?.auth &&
    authContextQuery.data.auth?.verified === true
  );
}
