import { combineReducers } from 'redux';
import app from './App/slice';
import invite from './Invite/slice';
import reset from './Reset/reducer';
import { IamReduxStore } from './types';

export default combineReducers<IamReduxStore>({
  app,
  invite,
  reset,
});
