import { ViemChains, ViemTransports } from 'components/Wallet/evm/types';
import { Environment } from 'types/app';
import { http } from 'viem';
import { cookieStorage, createConfig, createStorage } from 'wagmi';
import {
  coinbaseWallet,
  injected,
  safe,
  walletConnect,
} from 'wagmi/connectors';

const TEST_WALLETCONNECT_PROJECT_ID = 'e39e5a24285fe0a946cd3b6d9eada7af';
const PROD_WALLETCONNECT_PROJECT_ID = '9dfea1e28fa874dc0d8c95fb02d58886';

const appName = 'Monerium';
const appIcon = 'https://monerium.app/icon.svg';
const appDescription =
  'Monerium is an Electronic Money Institution authorized to issue money on blockchains';
const appUrl = 'https://monerium.app';

export const config = (env: Environment, chains: ViemChains) => {
  const projectId =
    env === 'production'
      ? PROD_WALLETCONNECT_PROJECT_ID
      : TEST_WALLETCONNECT_PROJECT_ID;

  const transports = chains.reduce((acc: ViemTransports, chain) => {
    const key = chain.id as keyof ViemTransports;
    acc[key] = http() as ViemTransports[keyof ViemTransports];
    return acc;
  }, {} as ViemTransports);

  const isServer = typeof window === 'undefined';

  return createConfig({
    // No need to poll and cache on server
    cacheTime: isServer ? undefined : 4_000,
    pollingInterval: isServer ? undefined : 4_000,

    ssr: true,
    multiInjectedProviderDiscovery: false,
    chains: chains,
    transports: transports,
    storage: createStorage({
      storage: cookieStorage,
    }),
    connectors: isServer
      ? []
      : [
          injected({
            shimDisconnect: false,
          }),
          walletConnect({
            metadata: {
              name: appName,
              description: appDescription,
              url: appUrl,
              icons: [appIcon],
            },
            projectId,
            qrModalOptions: {
              themeVariables: { '--wcm-z-index': '9999999' },
              explorerRecommendedWalletIds: [
                '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f', // Safe
                '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927', // Ledger
                'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18', // Zerion
                'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef', // imToken
                'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask
                '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
                'bc949c5d968ae81310268bf9193f9c9fb7bb4e1283e1284af8f2bd4992535fd6', // Argent
                // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
              ],
            },
          }),
          coinbaseWallet({
            appName: appName,
            appLogoUrl: appIcon,
            preference: {
              options: 'all',
              keysUrl:
                env !== 'production'
                  ? 'https://keys-dev.coinbase.com/connect'
                  : 'https://keys.coinbase.com/connect',
            },
          }),
          safe({
            // shimDisconnect: true,
            allowedDomains: [
              /app\.safe\.global$/,
              /^.*\.coinshift\.global$/,
              /localhost:5173$/,
            ],
          }),
        ],
  });
};
