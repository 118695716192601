export const STEP_SIGNUP = 'PARTNER_AUTH_STEP_SIGNUP';
export const STEP_LOGIN = 'PARTNER_AUTH_STEP_LOGIN';
export const STEP_VERIFY_EMAIL = 'PARTNER_AUTH_STEP_VERIFY_EMAIL';
export const STEP_USER_KYC = 'PARTNER_AUTH_STEP_USER_KYC';
export const STEP_START = 'PARTNER_AUTH_STEP_START';
export const STEP_PROFILE_KYC = 'PARTNER_AUTH_STEP_PROFILE_KYC';
export const STEP_PROFILE_KYC_REJECTED = 'PARTNER_AUTH_STEP_PROFILE_REJECTED';
export const STEP_CONNECT_WALLET = 'PARTNER_AUTH_STEP_CONNECT_WALLET';
export const STEP_CREATE_ACCOUNT = 'PARTNER_AUTH_STEP_CREATE_ACCOUNT';
export const STEP_REQUEST_IBAN = 'PARTNER_AUTH_STEP_REQUEST_IBAN';
export const STEP_END = 'PARTNER_AUTH_STEP_END';
export const LIST_REQUEST = 'PARTNER_LIST_REQUEST';
export const LIST_SUCCESS = 'PARTNER_LIST_SUCCESS';
export const LIST_FAILURE = 'PARTNER_LIST_FAILURE';
export const READ_REQUEST = 'PARTNER_READ_REQUEST';
export const READ_SUCCESS = 'PARTNER_READ_SUCCESS';
export const READ_FAILURE = 'PARTNER_READ_FAILURE';

export default {
  STEP_START,
  STEP_SIGNUP,
  STEP_LOGIN,
  STEP_VERIFY_EMAIL,
  STEP_USER_KYC,
  STEP_PROFILE_KYC,
  STEP_PROFILE_KYC_REJECTED,
  STEP_CONNECT_WALLET,
  STEP_CREATE_ACCOUNT,
  STEP_REQUEST_IBAN,
  STEP_END,
  LIST_REQUEST,
  LIST_SUCCESS,
  LIST_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
};
