/**
 * For React Query to handle errors properly,
 * we need to return the native fetch response and
 * reject Promises in json.
 */

import ValidationError from 'utils/error';

type OptionsType = RequestInit & { skipHeaders?: boolean };

export const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const error = await response.json();
    return Promise.reject(new ValidationError(error));
  }
  return response.json();
};

export const handleError = async (error: ValidationError) => {
  throw error;
};

export const getBody = (data: unknown) => {
  if (data) {
    if (typeof data === 'string' || data instanceof FormData) return data;
    if (typeof data === 'object') return JSON.stringify(data);
  }
  return undefined;
};

const request = async (url: string, allOptions: OptionsType = {}) => {
  const { headers, skipHeaders, ...options } = allOptions;

  const apiUrl = typeof window !== 'undefined' ? window.App?.apiUrl : '';
  const isProxy = typeof window !== 'undefined' ? window.App?.isProxy : false;
  const isApiUrl = url.startsWith('/api');

  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const defaultOptions: OptionsType = {
    method: 'POST', // handy with API backends
    mode: apiUrl ? 'cors' : 'same-origin',
    credentials: apiUrl && !isProxy ? 'include' : 'same-origin',
    headers: !skipHeaders
      ? {
          ...defaultHeaders,
          ...(headers || {}),
        }
      : {},
    ...options,
  };
  let fetchUrl = url;
  let fetchOptions = options;

  if (isApiUrl) {
    if (isProxy) {
      fetchUrl = url;
      fetchOptions = options;
    } else {
      fetchUrl = `${apiUrl}${url}`;
      fetchOptions = defaultOptions;
    }
  }

  return fetch(fetchUrl, fetchOptions).then(handleResponse).catch(handleError);
};

const get = (url: string, options: OptionsType = {}) => {
  return request(url, { ...options, method: 'GET' });
};
const post = (url: string, body?: unknown, options: OptionsType = {}) =>
  request(url, {
    ...options,
    method: 'POST',
    body: getBody(body),
  });

const put = (url: string, body?: unknown, options: OptionsType = {}) => {
  return request(url, { ...options, method: 'PUT', body: getBody(body) });
};

const patch = (url: string, body?: unknown, options: OptionsType = {}) => {
  return request(url, { ...options, method: 'PATCH', body: getBody(body) });
};
const del = (url: string, body?: unknown, options: OptionsType = {}) => {
  return request(url, { ...options, method: 'DELETE', body: getBody(body) });
};

const upload = (
  url: string,
  data?: Record<string, string | File>,
  options: OptionsType = {},
) => {
  const formData = new FormData();
  if (data && typeof data === 'object') {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      formData.append(key, data[key]);
    }
  }
  return request(url, {
    ...options,
    method: 'POST',
    body: formData,
    skipHeaders: true,
  });
};

export default {
  request,
  get,
  post,
  put,
  patch,
  del,
  upload,
};
