import { useQuery } from '@tanstack/react-query';
import { useSharedAppContext } from 'providers/AppConfig';
import { keyPrefix } from 'providers/QueryProvider';
import { ChainUniqueName, EcosystemChainId } from 'types/emoney/Chain';
import { CurrencyCode, Token } from 'types/emoney/Token';
import { equal } from 'utils/address';
import { getTokens as get } from './service';

const useTokens = () => {
  const { tokens } = useSharedAppContext();
  return useQuery<Token[]>({
    queryKey: keyPrefix(['emoney-tokens-list']),
    queryFn: get,
    initialData: tokens,
    gcTime: Infinity, // Keep the cache forever
    staleTime: Infinity,
    retry: false,
  });
};

/**
 * Filter down to a single token based on currency and chain
 */
const useToken = (
  currency?: CurrencyCode,
  chain?: ChainUniqueName | EcosystemChainId,
) => {
  const tokensQuery = useTokens();

  return useQuery<Token>({
    queryKey: keyPrefix(['emoney-token', { currency, chain }]),
    queryFn: () => {
      if (!tokensQuery.data) {
        throw new Error('Tokens data is not available');
      }
      const token = tokensQuery.data.find(
        (token) =>
          equal(token.currency, currency) && equal(token.chainId, chain),
      );
      if (!token) {
        throw new Error('Token not found');
      }

      return token;
    },
    enabled: tokensQuery.isSuccess && !!currency && !!chain, // Only run when both are defined and tokensQuery is successful
    staleTime: Infinity,
  });
};

export { useToken, useTokens };
