import evmChains from 'components/Wallet/evm/chains';
import { ChainUniqueName } from 'types/emoney/Chain';

export const chainSortOrder: ChainUniqueName[] = [
  'gnosis',
  'chiado',
  'noble',
  'grand',
  'florin',
  'linea',
  'lineasepolia',
  'arbitrum',
  'arbitrumsepolia',
  'polygon',
  'amoy',
  'ethereum',
  'sepolia',
  'scroll',
  'scrollsepolia',
  'camino',
  'columbus',
];

export const isEvmChainId = (chainId?: number | string): boolean => {
  return chainId
    ? !!evmChains.find((chain) => chain.id === Number(chainId))
    : false;
};

// as defined by Safe
export const chainShortName: Record<string, string> = {
  1: 'eth',
  100: 'gno',
  137: 'matic',
  200: 'eth',
  42161: 'arb1',
  11155111: 'sep',
  10200: 'chiado',
  59144: 'linea',
  534352: 'scr',
};
