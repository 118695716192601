import { useTokens } from 'components/Token/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import { useCallback, useMemo } from 'react';
import { useLogger } from 'services/logger';
import {
  Chain,
  ChainName,
  ChainUniqueName,
  EcosystemChainId,
  EcosystemId,
} from 'types/emoney/Chain';
import { getFallbackChain } from './util';
import {
  getAddressUrl,
  getBlockUrl,
  getExplorerTokenByAddressUrl,
  getTokenUrl,
  getTransactionUrl,
} from './util/explorer';
import { getChainsFromTokens } from './util/filter';

export function useSupportedChains() {
  const { data: tokens, isSuccess } = useTokens();
  const { environment } = useSharedAppContext();
  const { logMessage } = useLogger();

  if (!tokens?.length) {
    logMessage({
      message: 'MAJOR ISSUE, no tokens received from the server',
      severity: 'error',
    });
  }

  let chainsFromTokens: Chain[];

  if (environment === 'sandbox') {
    // Hide Gnosis Mainnet in Sandbox
    chainsFromTokens = getChainsFromTokens(
      tokens?.filter((t) => t.chain !== 'gnosis'),
    );
  } else {
    chainsFromTokens = getChainsFromTokens(tokens);
  }

  return isSuccess ? chainsFromTokens : [getFallbackChain(environment)];
}

/** Use to find chain and/or chain data */
export const useChains = (kind?: EcosystemId) => {
  const chains = useSupportedChains();

  const filteredChains = useMemo(() => {
    if (kind) {
      return chains.filter((c) => c?.kind === kind);
    }
    return chains;
  }, [chains, kind]);

  return filteredChains;
};

export const useChain = (
  id?: ChainUniqueName | ChainName | EcosystemChainId,
) => {
  const chains = useSupportedChains();

  const chain = useMemo(
    () =>
      chains.find((c) => c?.id === id) ||
      chains.find((c) => c?.chain === id) ||
      chains.find((c) => c?.chainId === `${id}`),
    [chains, id],
  );

  /**
   * Retrieves the default block explorer URL for a given chain in the specified environment.
   *
   * @returns {string} - The URL  of the default block explorer for the specified chain.
   */
  const explorerUrl = useCallback(() => {
    return chain?.explorerUrl;
  }, [chain]);

  /**  Constructs the full URL to view an address on the block explorer */
  const explorerAddressUrl = useCallback(
    (address: string) => getAddressUrl(chain, address),
    [chain],
  );

  /** Constructs the full URL for a transaction on the block explorer. */
  const explorerTransactionUrl = useCallback(
    (hash: string) => getTransactionUrl(chain, hash),
    [chain],
  );

  /** Constructs the full URL for a token on the block explorer. */
  const explorerTokenUrl = useCallback(
    (contractAddress: string) => getTokenUrl(chain, contractAddress),
    [chain],
  );

  /** Constructs the full URL for a block on the block explorer. */
  const explorerBlockUrl = useCallback(
    (hash: string) => getBlockUrl(chain, hash),
    [chain],
  );

  /** Constructs the full URL for a token, filtered by address on the block explorer. */
  const explorerTokenByAddressUrl = useCallback(
    (contractAddress: string, address: string) =>
      getExplorerTokenByAddressUrl(chain, contractAddress, address),
    [chain],
  );

  const memoizedValues = useMemo(() => {
    return {
      chain,
      explorerUrl,
      explorerAddressUrl,
      explorerTransactionUrl,
      explorerTokenUrl,
      explorerBlockUrl,
      explorerTokenByAddressUrl,
    };
  }, [id]);

  return memoizedValues;
};
