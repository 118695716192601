'use client';

import CosmosProvider from 'components/Wallet/cosmos/Provider';
import React from 'react';
import EvmProvider from './evm/Provider';

const WalletConnector = ({ children }: { children: React.ReactNode }) => {
  return (
    <EvmProvider>
      <CosmosProvider>{children}</CosmosProvider>
    </EvmProvider>
  );
};
export default WalletConnector;
